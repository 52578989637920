<script setup lang="ts">

import { findLensModel }  from "@/stores/lensModelCollection"
import { computed } from "vue";


const props = defineProps({
    titulo : {
        type: String,
        required: true
    },
    aConstant: {
        type: Number,
        required: true
    },
    idLen: {
      type: Number,
      required: true
    }
})

const model = computed(() => {
    return findLensModel(Number(props.idLen))?.name
})

</script>

<template>
    <header class="result-lens__header">
        <div class="result-lens__title-container">
            <h2> {{ titulo }} </h2>
        </div>

        <div class="result-lens__a-constant-info">
            <p class="text-topic-value">
              <span class="text-topic-value__topic">A-Constant</span>:
              <span class="text-topic-value__value">{{ aConstant }}</span>
            </p>

            <span v-if="model" class="tag">Model: {{ model }}</span>
        </div>
    </header>

    <div class="result-lens__result-container">
        <table class="table-result-lens"></table>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"
.result-lens
  padding: 2.08em 7.43% 2.08em 7.43%

  &__title-container
    font-size: 2em
    padding-bottom: 0.8em

  &__a-constant-info
    display: flex
    gap: 1.6em
    font-size: 1.4em
    padding-bottom: 1.6em
    align-items: flex-start
    flex-direction: column

.table-result-lens
  border-collapse: collapse
  width: 100%
    
.tag
  display: inline-block
  padding: 0.3em 0.8em
  background-color: colors.$secondary-light
  border-radius: 0.3em

</style>